<template>
		<div class="login-form">
			<v-toolbar flat>
				<v-btn
				icon
				href="/"
				>
				<v-icon>mdi-home-variant-outline</v-icon>
				</v-btn>

				
			</v-toolbar>
			<v-container fluid fill-height>
				<v-layout align-center justify-center>
					<v-flex xs12 sm8 md4 lg4>
						<v-card class="pa-4" outlined>
							<v-card-text>
								<div class="layout column align-center">
									<img src="@/assets/next-level-logo.svg" alt="Next Level Login" width="150" height="150">
									<h1 class=" flex my-8 font-weight-light">
										Login to your account
									</h1>
								
								</div>
								<v-form>
									<v-text-field
										append-icon="mdi-email"
										outlined
										name="login"
										label="Email"
										type="text"
										v-model="email"
										:error="error"
										:rules="email_rules"
										
									/>
									<v-text-field
										outlined
										:type="hidePassword ? 'password' : 'text'"
										:append-icon="
											hidePassword
												? 'mdi-eye-off-outline'
												: 'mdi-eye-outline'
										"
										name="password"
										label="Password"
										id="password"
										:rules="password_rules"
										v-model="password"
										:error="error"
										@click:append="hidePassword = !hidePassword"

									/>
								</v-form>
							
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn 
									large 
									block 
									color="teal white--text" 
									@click="login" 
									:loading="loading">Login</v-btn>

							</v-card-actions>
							</v-card-text>
							<p 
							v-if="enable_forget_password"
							class="mt-4 font-weight-light body-2">
								If you forget your password you can <router-link to="/reset-password">Reset Password</router-link>.</p>
								<p v-if="enable_signup"
									class=" font-weight-light body-2"
									>
									if you do not have an account you can <router-link to="/signup">Create a New Account</router-link> 
								</p>


						</v-card>
					</v-flex>
				</v-layout>
			</v-container>
			<v-snackbar
				v-model="showResult"
				:timeout="2000"
				top>
				{{ result }}
			</v-snackbar>
		</div>
</template>

<script>
import { login} from '@/api/user'
export default {
	data() {
		return {
			
			loading: false,
			email: '',
			
			email_rules:[
				v => !!v || 'Email is required',
				v => /.+@.+/.test(v) || 'Email must be valid',

			],
			password: "",
			password_rules:[
				v => !!v || 'Password is required',
				v => v.length<160 || 'Password is too long',
				v => v.length>5 || 'Password is too short',
			],
			hidePassword: true,
			error: false,
			showResult: false,
			error_message:"",
			result: '',
			rules: {
				required: value => !!value || 'Required.'
			}
		}
	},

	computed: {
		enable_signup:function(){
			return process.env.VUE_APP_ENABLE_SIGNUP =='T'
		},
		enable_forget_password:function(){
			return process.env.VUE_APP_ENABLE_FORGET_PASSWORD =='T'
		}
	},

	methods: {
		login() {
			const vm = this;

			if (!vm.email || !vm.password) {
				console.log("invalid data");
				vm.result = "Email and Password can't be empty.";
				vm.showResult = true;

				return;
			}
			// check online 
			// 
			this.loading=true;
			return new Promise((resolve, reject) => {
			login({ email: vm.email.trim(), password: vm.password }).then(response => {
				if (response.error ==false){
					console.log("session_key: ",response.session_key)
					const local_save={
						"session_key":response.session_key,
						"name":response.user.name,
						"email":response.user.email,
						"phone":response.user.phone,
						"status":response.user.status,
						"subscription_active":response.subscription.active,
						"subscription_start_date":response.subscription.start_date,
						"subscription_end_date":response.subscription.end_date,
						"subscription_package":response.subscription.package,
						};
					this.$store.commit('login', local_save);
					this.$store.commit('clear_market_list');
					this.$store.commit('set_market', response.market);
					if (response.market.length==0){
						//redirect to add market
						vm.$router.push({ name: 'AddMarket' });
					}else{
						vm.$router.push({ name: 'Dashboard' });	
					}
					this.loading=false;

					
					resolve()
				}else{
					// show error
					vm.result=true;
					vm.result=response.error_message;
					vm.showResult = true;
					this.loading=false;
				}
			}).catch(error => {
				reject(error)
			})
		})

		}
	}
}
</script>

<style scoped lang="css">
.login-form{
	

}
</style>