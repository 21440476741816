import request from '@/utils/request'

export function signup(data) {
	return request({
		url: '/signup',
		method: 'post',
		data:data
	})
}

export function verify_sms(data) {
	return request({
		url: '/verify-sms',
		method: 'post',
		data:data
	})
}


export function signup_invite(data) {
	return request({
		url: '/signup-invite',
		method: 'post',
		data:data
	})
}

export function verify_sms_invite(data) {
	return request({
		url: '/verify-sms-invite',
		method: 'post',
		data:data
	})
}

export function login(data) {
	return request({
		url: '/login',
		method: 'post',
		data:data
	})
}

export function forget_password(data) {
	return request({
		url: '/forget-password',
		method: 'post',
		data:data
	})
}

export function forget_password_verify(data) {
	return request({
		url: '/forget-password-verify',
		method: 'post',
		data:data
	})
}

export function reset_password(data) {
	return request({
		url: '/reset-password',
		method: 'post',
		data:data
	})
}

export function get_profile() {
	return request({
		url: '/profile',
		method: 'post'		
	})
}
export function update_name(data) {
	return request({
		url: '/update-name',
		method: 'post',
		data:data
	})
}
export function update_phone(data) {
	return request({
		url: '/update-phone',
		method: 'post',
		data:data
	})
}
export function update_email(data) {
	return request({
		url: '/update-email',
		method: 'post',
		data:data
	})
}

export function update_password(data) {
	return request({
		url: '/update-password',
		method: 'post',
		data:data
	})
}

export function check_invite(data) {
	return request({
		url: '/check-invite-link',
		method: 'post',
		data:data
	})
}

export function get_invite_code() {
	return request({
		url: '/get-invite-link',
		method: 'post',
		
	})
}

export function get_invite_stat() {
	return request({
		url: '/invite-stat',
		method: 'post',
		
	})
}